import styled from "styled-components";

export const ResetButtonStyles = styled.button`
  margin: 0;
  padding: 0;
  border: 0 none;
  background: transparent;
  @media print {
    display: none;
  }
`;
